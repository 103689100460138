import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Container } from '@/components/container';
import { SliderButtonLeft } from '@/components/ui/buttons/slider-buttons/SliderButtonLeft';
import { SliderButtonRight } from '@/components/ui/buttons/slider-buttons/SliderButtonRight';
import { Skeleton } from '@/components/ui/loaders/Skeletons/Skeletons';

import { getImageFormatUrl } from '@/helpers/string/getImageFormatUrl';

import { useGetInstagramReviewsQuery } from '@/store/api/reviews.api';

import { ISwiper } from '@/types/ISwiper';

import styles from './Reviews.module.scss';
import { ReviewItem } from './review-item/ReviewItem';

const breakPoints = {
  0: {
    slidesPerView: 2.1,
    centeredSlides: true,
    spaceBetween: 6,
  },
  400: {
    slidesPerView: 2.25,
    spaceBetween: 6,
    centeredSlides: true,
  },
  480: {
    slidesPerView: 1.5,
    spaceBetween: 16,
    centeredSlides: false,
  },
  550: {
    slidesPerView: 2.2,
    spaceBetween: 16,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 16,
  },
  998: {
    slidesPerView: 4,
    spaceBetween: 16,
  },
};

export const Reviews = ({
  loop = false,
  limit,
  ids,
  titleComponent = 'h2',
}: {
  loop?: boolean;
  limit?: number;
  ids?: number[];
  titleComponent?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}) => {
  const { data, isLoading } = useGetInstagramReviewsQuery({ ids, limit });

  const [swiper, setSwiper] = useState<ISwiper | null>(null);

  const handleTapNext = () => swiper?.slideNext();
  const handleTapPrev = () => swiper?.slidePrev();

  const Title = titleComponent;

  return (
    <div>
      <Container>
        <div className={styles.header}>
          <Title className={styles.h2}>Отзывы</Title>

          <div className={styles.header__arrows}>
            <SliderButtonLeft onClick={handleTapPrev} />
            <SliderButtonRight onClick={handleTapNext} />
          </div>
        </div>
      </Container>
      <Container className={styles.noPadding}>
        <div>
          <Swiper
            initialSlide={1}
            slidesPerView={4}
            spaceBetween={16}
            breakpoints={breakPoints}
            className={styles.swiper}
            loop={loop}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
            {isLoading
              ? [...new Array(4)].map((_, i) => (
                  <SwiperSlide key={i}>
                    <Skeleton className={styles.skeleton} />
                  </SwiperSlide>
                ))
              : data?.map((review) => {
                  const imageUrl = getImageFormatUrl(review.image?.[0], 'medium');
                  const avatarUrl = getImageFormatUrl(review.avatar?.[0], 'small');

                  return (
                    <SwiperSlide key={review.id}>
                      <ReviewItem
                        nickname={review.nickname}
                        image={imageUrl}
                        avatar={avatarUrl}
                        text={review.text}
                      />
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        </div>
      </Container>
    </div>
  );
};
