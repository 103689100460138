import clsx from 'clsx';
import { motion } from 'framer-motion';
import Link from 'next/link';
import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';

import Image from '@/components/image/Image';
import ProductCard from '@/components/product-card/ProductCard';
import ProductCardSkeleton from '@/components/ui/loaders/Skeletons/ProductCardSkeleton/ProductCardSkeleton';

import { getImageFormatUrl } from '@/helpers/string/getImageFormatUrl';

import { useHasMounted } from '@/hooks/useHasMounted';

import { IProductMedia, IRubricBundle } from '@/types/new/products';

import styles from './RubricsTabsSection.module.scss';
import { useRubricsTabs } from './useRubricsTabs';

type Props = HTMLAttributes<HTMLDivElement> & {
  rubricBundle: IRubricBundle;
  centered?: boolean;
};

const Banner = ({
  className,
  isLoading,
  rubricBundleItem,
}: HTMLAttributes<HTMLDivElement> & {
  rubricBundleItem: IRubricBundle['items'][0];
  isLoading: boolean;
}) => {
  const {
    banner_title,
    banner_css,
    banner_bg_to,
    banner_bg_from,
    banner_media,
    banner_text,
    banner_text_css,
    banner_url,
  } = rubricBundleItem;

  const fallbackBackground = 'linear-gradient(160deg, #FF3B3B 1.45%, #FF8080 100%)';

  return (
    <Link
      href={banner_url ?? '/'}
      className={clsx(className, isLoading && styles.loading, styles.banner)}
      style={{
        background:
          banner_bg_to && banner_bg_from
            ? `linear-gradient(160deg, ${banner_bg_from} 1.45%, ${banner_bg_to} 100%)`
            : fallbackBackground,
      }}
    >
      <div className={styles.body}>
        <div
          className={styles.title}
          style={banner_css && typeof banner_css !== 'string' ? banner_css : {}}
        >
          {banner_title}
        </div>
        <p
          className={styles.description}
          style={banner_text_css && typeof banner_text_css !== 'string' ? banner_text_css : {}}
        >
          {banner_text}
        </p>
      </div>
      <Image
        className={styles.image}
        src={getImageFormatUrl(banner_media as IProductMedia, 'medium')}
        fill
        alt={banner_title}
      />
    </Link>
  );
};

const Tabs = ({
  className,
  tabs,
  currentTabIndex,
  onTabChange,
}: {
  className?: string;
  tabs: { tabName: string; rubricId: number; productsQuantity: number }[];
  currentTabIndex: number;
  onTabChange: (rubricId: number, productsQuantity: number, index: number) => void;
}) => {
  const hasMounted = useHasMounted();
  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const [tabsContainerWidth, setTabsContainerWidth] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    if (tabsContainerRef.current) {
      const width = tabsContainerRef.current.scrollWidth - tabsContainerRef.current.offsetWidth;
      setTabsContainerWidth(width);
    }
  }, [tabsContainerRef, hasMounted]);

  return (
    <div className={clsx(styles.tabs, className)} ref={tabsContainerRef}>
      <motion.div
        className={styles.innerSlider}
        drag="x"
        dragConstraints={{ left: -tabsContainerWidth, right: 0 }}
        dragElastic={{
          left: 0.2,
          right: 0.2,
        }}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
      >
        {tabs.map(({ tabName, rubricId, productsQuantity }, index) => (
          <button
            key={tabName}
            className={clsx(styles.tab, index === currentTabIndex && styles.active)}
            style={{ pointerEvents: isDragging ? 'none' : 'auto' }}
            onClick={() => onTabChange(rubricId, productsQuantity, index)}
          >
            {tabName}
          </button>
        ))}
      </motion.div>
    </div>
  );
};

export const RubricsTabsSection = ({
  className,
  rubricBundle,
  centered = false,
  ...props
}: Props) => {
  const { onTabChange, tabs, isLoading, products, productsForLoading, currentTabIndex } =
    useRubricsTabs({ initialRubricBundle: rubricBundle });

  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <h2 className={clsx(styles.title, centered && styles.centered)}>{rubricBundle.name}</h2>
      <Tabs
        className={centered ? styles.centered : undefined}
        tabs={tabs}
        currentTabIndex={currentTabIndex}
        onTabChange={onTabChange}
      />
      <div className={styles.grid}>
        {isLoading
          ? [...new Array(productsForLoading)].map((_, index) => (
              <ProductCardSkeleton key={index} />
            ))
          : products.map((product) => (
              <ProductCard key={product.id} product={product} imageProps={{ priority: false }} />
            ))}
        <Banner
          className={styles.desktop}
          rubricBundleItem={rubricBundle.items[currentTabIndex]}
          isLoading={isLoading}
        />
      </div>
      <Banner
        className={styles.mobile}
        rubricBundleItem={rubricBundle.items[currentTabIndex]}
        isLoading={isLoading}
      />
    </div>
  );
};
