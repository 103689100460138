import clsx from 'clsx';
import Link from 'next/link';
import { HTMLAttributes } from 'react';

import { Container } from '@/components/container';
import { Image } from '@/components/image';

import { ITiledBundle } from '@/types/new/tiled-bundles';

import styles from './SelectionsGrid.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  bundle: ITiledBundle;
};

export const SelectionsGrid = ({ bundle, className, ...rest }: Props) => {
  const bigItem = bundle.big_tile;
  const smallItems = bundle.bundle_small_tiles.map((item) => item.small_tile);

  return (
    <Container className={styles.container}>
      <div className={clsx(styles.wrapper, className)} {...rest}>
        <div className={styles.grid}>
          <Link
            key={bigItem.name}
            href={bigItem.href}
            className={clsx(styles.element, styles.large)}
          >
            <span className={styles.title}>{bigItem.name}</span>
            <Image className={styles.image} fill priority src={bigItem.image.url} alt="" />
          </Link>
          {smallItems.map(({ name, media, href }) => (
            <Link key={name} href={href} className={clsx(styles.element, styles.default)}>
              <span className={styles.title}>{name}</span>
              <Image className={styles.image} fill priority src={media.url} alt="" />
            </Link>
          ))}
        </div>
      </div>
    </Container>
  );
};
