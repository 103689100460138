import clsx from 'clsx';
import { useInView } from 'framer-motion';
import Link from 'next/link';
import { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import SwiperCore, { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Image } from '@/components/image';

import { analyticMetric } from '@/helpers/analytics-metric';

import { useHasMounted } from '@/hooks/useHasMounted';

import { IBanner } from '@/types/new/banners';

import styles from './Banner.module.scss';
import { NavigationButton } from './navigation-button/NavigationButton';
import { Pagination } from './pagination/Pagination';

type Props = HTMLAttributes<HTMLDivElement> & { banners: IBanner[] };

const options: SwiperOptions = {
  slidesPerView: 1.16,
  spaceBetween: 12,
  centeredSlides: true,
  loop: true,
  loopAdditionalSlides: 1,
  breakpoints: {
    999: {
      slidesPerView: 1,
    },
  },
};

export const BannerSlider = ({ className, banners, ...rest }: Props) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const initialBanner = useMemo(() => banners[0], []);
  const hasMounted = useHasMounted();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(wrapperRef, { once: true });

  useEffect(() => {
    isInView &&
      analyticMetric.viewingPromotions([
        {
          id: banners[activeIndex].id,
          name: banners[activeIndex].name,
          position: activeIndex,
        },
      ]);
  }, [banners, isInView, activeIndex]);

  const onBannerSlideClickHandle = () => {
    analyticMetric.selectPromotion({
      id: banners[activeIndex].id,
      name: banners[activeIndex].name,
      creative_name: '',
      position: activeIndex,
    });
  };

  return (
    <div className={clsx(styles.wrapper, className)} ref={wrapperRef} {...rest}>
      {hasMounted ? (
        <>
          <Swiper
            {...options}
            className={styles.slider}
            onInit={(swiper) => setSwiper(swiper)}
            onSlideChange={({ realIndex }) => setActiveIndex(realIndex)}
          >
            {banners.map(({ url, media, name }, index) => {
              const shouldBeLink = url && url !== '/';
              const Component = shouldBeLink ? Link : 'div';
              const props: any = shouldBeLink ? { href: url } : {};

              return (
                <SwiperSlide key={media[0].url} className={styles.slide}>
                  <Component onClick={onBannerSlideClickHandle} {...props} className={styles.item}>
                    <Image src={media[0].url} alt={name} fill priority={index === 0} />
                  </Component>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Pagination
            className={styles.pagination}
            currentIndex={activeIndex}
            numberOfBullets={banners.length}
            onBulletClick={(index) => swiper?.slideTo(index)}
          />
          {banners.length > 1 && (
            <>
              <NavigationButton
                className={styles.left}
                direction="left"
                onClick={() => swiper?.slidePrev()}
              />
              <NavigationButton
                className={styles.right}
                direction="right"
                onClick={() => swiper?.slideNext()}
              />
            </>
          )}
        </>
      ) : (
        <Link href={initialBanner.url} className={`${styles.item} ${styles.item_placeholder}`}>
          <Image src={initialBanner.media[0].url} alt={initialBanner.name} fill priority />
        </Link>
      )}
    </div>
  );
};
