import { useCallback, useMemo, useRef, useState } from 'react';

import { ProductService } from '@/services/product.service';

import { IProductNew, IRubricBundle } from '@/types/new/products';

interface Props {
  initialRubricBundle: IRubricBundle;
}

export const useRubricsTabs = ({ initialRubricBundle }: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false);
  const [actualProducts, setActualProducts] = useState<IProductNew[]>(
    initialRubricBundle.items[0].products as IProductNew[]
  );

  const productsForLoading = useMemo(
    () => initialRubricBundle.items[currentTabIndex].products_quantity,
    [currentTabIndex, initialRubricBundle.items]
  );

  const tabs = useMemo(
    () =>
      initialRubricBundle.items.map((el) => ({
        tabName: el.tab_name,
        rubricId: el.rubric_id,
        productsQuantity: el.products_quantity,
      })),
    [initialRubricBundle.items]
  );

  const cacheRef = useRef<{ [key: number]: IProductNew[] }>({
    [initialRubricBundle.items[0].rubric_id]: initialRubricBundle.items[0]
      .products as IProductNew[],
  });

  const onTabChange = useCallback(
    async (newRubricId: number, productsToFetch: number, index: number) => {
      if (index === currentTabIndex) return;

      try {
        setIsProductsLoading(true);
        setCurrentTabIndex(index);

        if (cacheRef.current[newRubricId]) {
          setActualProducts(cacheRef.current[newRubricId]);
        } else {
          const { data } = await ProductService.getByRubricId(newRubricId, 0, productsToFetch);
          cacheRef.current[newRubricId] = data.products;
          setActualProducts(data.products);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsProductsLoading(false);
      }
    },
    [currentTabIndex]
  );

  return {
    isLoading: isProductsLoading,
    products: actualProducts,
    tabs,
    onTabChange,
    productsForLoading,
    currentTabIndex,
  };
};
