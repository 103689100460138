import FreeDeliveryImage from './1.webp';
import JustInTimeDeliveryImage from './2.webp';
import ExpressDeliveryImage from './3.webp';
import SupportImage from './4.webp';
import OfficeMapImage from './office-map.webp';
import PickupMapImage from './pickup-map.webp';

export {
  FreeDeliveryImage,
  JustInTimeDeliveryImage,
  ExpressDeliveryImage,
  SupportImage,
  OfficeMapImage,
  PickupMapImage,
};
