import clsx from 'clsx';
import { FC, useCallback } from 'react';

import { Image } from '@/components/image';

import { useHasMounted } from '@/hooks/useHasMounted';

import styles from './ReviewItem.module.scss';

interface ReviewItemProps {
  nickname: string;
  avatar?: string;
  image?: string;
  text?: string | null;
}

export const instagramIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0 0 48 48"
  >
    <path d="M 16.5 5 C 10.16639 5 5 10.16639 5 16.5 L 5 31.5 C 5 37.832757 10.166209 43 16.5 43 L 31.5 43 C 37.832938 43 43 37.832938 43 31.5 L 43 16.5 C 43 10.166209 37.832757 5 31.5 5 L 16.5 5 z M 16.5 8 L 31.5 8 C 36.211243 8 40 11.787791 40 16.5 L 40 31.5 C 40 36.211062 36.211062 40 31.5 40 L 16.5 40 C 11.787791 40 8 36.211243 8 31.5 L 8 16.5 C 8 11.78761 11.78761 8 16.5 8 z M 34 12 C 32.895 12 32 12.895 32 14 C 32 15.105 32.895 16 34 16 C 35.105 16 36 15.105 36 14 C 36 12.895 35.105 12 34 12 z M 24 14 C 18.495178 14 14 18.495178 14 24 C 14 29.504822 18.495178 34 24 34 C 29.504822 34 34 29.504822 34 24 C 34 18.495178 29.504822 14 24 14 z M 24 17 C 27.883178 17 31 20.116822 31 24 C 31 27.883178 27.883178 31 24 31 C 20.116822 31 17 27.883178 17 24 C 17 20.116822 20.116822 17 24 17 z"></path>
  </svg>
);

const defaultColor = `hsl(72.65076017335586, 38.821609240134016%, 85.31492118335075%)`;

export const ReviewItem: FC<ReviewItemProps> = ({ nickname, avatar, image, text }) => {
  const hasMounted = useHasMounted();
  const getColor = useCallback(
    () =>
      'hsl(' +
      360 * Math.random() +
      ',' +
      (25 + 70 * Math.random()) +
      '%,' +
      (85 + 10 * Math.random()) +
      '%)',
    []
  );

  return (
    <div
      className={clsx(styles.wrapper, text && styles.wrapperWithText)}
      style={{ backgroundColor: hasMounted ? getColor() : defaultColor }}
    >
      {image && (
        <Image src={image} fill className={styles.image} loading="lazy" alt={`Отзыв ${nickname}`} />
      )}
      {text && (
        <div className={styles.textWrapper}>
          <div className={styles.text}>{text}</div>
        </div>
      )}

      <div className={styles.body}>
        <div className={styles.body__avatar}>
          {avatar && (
            <Image alt={`Отзыв ${nickname}`} src={avatar} loading="lazy" fill sizes="96px" />
          )}
        </div>
        <div className={styles.body__instagram}>
          <div className={styles.body__instagram_icon}>{instagramIcon}</div>
          <span className={styles.body__instagram_nickname}>@{nickname}</span>
        </div>
      </div>
    </div>
  );
};
